import React from "react"
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

const ChartThree = ({data}) => {

    //Length of data
    const leng = data.length

    //Calculate max value in array, looping not an option
    
    let maxOne = data[leng-1].LastYear;
    
    //Round the max value  
      const maxOneRou = Math.ceil(maxOne/100000)*100000

  return (
    <section>
      <div style={{margin:`0 auto 0 auto`}}>
        <h2>Price Compared to Local Areas</h2> 
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 15, left: 25 }} syncId="anyId"> 
        <Tooltip 
          formatter={time => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(time)}
          />
          <Legend />
          <Bar
            type="monotone"
            dataKey="LastYear"
            name="Price"
            stroke="Purple"
            fill="Purple"
            strokeWidth={1}
            isAnimationActive={false}
          />
          <XAxis
            dataKey="Location"
          />
          <YAxis
            interval={0}
            domain={[0, maxOneRou]}
            tickFormatter={time => new Intl.NumberFormat('en-GB').format(time)}
          />
          <CartesianGrid stroke="none" />
        </BarChart>
      </ResponsiveContainer>
      </div>
         </section>
  )
}

export default ChartThree
